<template>
    <div class="text-left">
        <div :style="{width: this.$oucy.pageWidth+'px'}" class="boxs">
            <div class="title">
                <span class="line"></span>
                {{title}}
                <!-- 招标公告 -->
            </div>
            <div class="box">
                <template>
                    <el-table :data="list" style="width: 100%" >
                        <el-table-column label="标题" align="left">
                            <div slot="header" slot-scope="scope" class="p-l-20 bold c-3">
                                   标题
                                 </div>

                            <div slot-scope="scope" @click="$oucy.go('/AfficheDetail?id='+scope.row.id+'&type='+type)" class="pointer p-l-20">
                                {{scope.row.adTitle ||scope.row.noticeTitle}}
                            </div>
                        </el-table-column>
                        <el-table-column prop="adUnit" label="招标单位" v-if="type==2">
                           
                            <div slot="header" slot-scope="scope" class="bold c-3">招标单位</div>
                        </el-table-column>
                        <el-table-column prop="" label="发布时间" align="right">
                            <div slot="header" slot-scope="scope" class="p-r-20 bold c-3">
                                   发布时间
                                 </div>
                            <div slot-scope="scope" class="p-r-20">
                                {{scope.row.adUpdatetime || scope.row.noticeUpdatetime}}
                            </div>
                        </el-table-column>
                    </el-table>
                </template>
<!--                 <el-pagination class="m-t-30" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                </el-pagination> -->
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "../../../util/oucyUtil";
import { bidding, notice } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Index",
    data() {
        return {
            title:'平台公告',
            type:1, //类型 1、平台公告 2、招标公告
            limit: 10,
            start: 0,
            list:[],
            prlductData: {},
            spuName: null,
            enterpriseId: null,
        }
    },
    mounted() {
        this.type = this.$route.query.type
        if(this.type == 1){
            this.title = '平台公告'
            
        }
        else{
            this.title = '招标公告'
        }
        this.$oucy.setTitle(this.title)
        this.init()
    },
    methods: {
        //初始化
        init() {
            if(this.type == 1){
                this.getANotice()
            }
            else{
                this.getBidding()
            }
        },
        // 产品列表
        search() {
            this.start = 0
            this.findAllFurnitureSpu()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.init();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.init();
        },

        //获取平台公告
        getANotice(){
            notice.getAllNotice({}).then(res=>{
                if(res){
                   // res = oucy.keyAs(res,"noticeTitle","title")
                   // res =  oucy.keyAs(res,"noticeCreatetime","createTime")
                    this.list = res
                }
            })
        },

        //获取招标公告
        getBidding(){
            bidding.getAllTender({}).then(res=>{
                if(res){
                   // res = oucy.keyAs(res,"adTitle","title")
                   // res =  oucy.keyAs(res,"adCreatetime","createTime")
                    this.list = res
                }
            })
        },
    },
}
</script>
<style scoped lang="less">
.boxs {
    margin: 0 auto;
    padding-bottom: 50px;
    .box {
        background: #fff;
    }
}

.title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    padding: 30px 0;
    display: flex;
    align-items: center;
    span.line {
        display: inline-block;
        width: 6px;
        height: 18px;
        background: #2090FF;
        border-radius: 2px;
        margin-right: 10px;
    }
}
</style>